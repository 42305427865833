import '@gouvfr/dsfr/dist/core/core.module.min.js'
import '@gouvfr/dsfr/dist/component/navigation/navigation.module.min.js'
import '@gouvfr/dsfr/dist/component/modal/modal.module.min.js'
import '@gouvfr/dsfr/dist/component/header/header.module.min.js'
import '@gouvfr/dsfr/dist/component/sidemenu/sidemenu.module.min.js'
import '@gouvfr/dsfr/dist/component/accordion/accordion.module.min.js'
import '@gouvfr/dsfr/dist/component/tab/tab.module.min.js'
import '@gouvfr/dsfr/dist/component/toggle/toggle.module.min.js'
import '@gouvfr/dsfr/dist/component/tooltip/tooltip.module.min.js'

export default defineNuxtPlugin(() => {})
