import { AppRoute, FilesRoute, getRouteOptions } from './utils'
import { BeneficiaryTab } from '~/utils/constants/beneficiary'
import { HistoryTab } from '~/utils/constants/history'
import { BeneficiaryNames } from '~/types/beneficiary'
import { formatFamilyFileName } from '~/utils/familyFile/formatFamilyFileName'
import { TypeSuivi } from '~/client/options'
import {
  FilterFileInstructionNotFilled,
  FilterFollowupNotFilled
} from '../constants/filters'

type OriginPage =
  | 'AppOverview'
  | 'AppBeneficiaries'
  | 'AppHistory'
  | 'AppFileBudgetInfoEdit'
  | 'AppFileBudgetIncomeEdit'
  | 'AppFileBudgetExpensesEdit'

// TODO rename item to something clearer
export interface FamilyFileUrlOptions {
  beneficiaryId?: string
  tab?: BeneficiaryTab

  item?: string
  originPage?: OriginPage
  originTab?: HistoryTab
}

function getFamilyFileRoute(
  familyFileId: string | undefined,
  options?: FamilyFileUrlOptions
) {
  if (!familyFileId) {
    return ''
  }

  const base = `${FilesRoute}/${familyFileId}`
  const suffix = getRouteOptions(options)

  return `${base}${suffix}`
}

export const routes = {
  AppFile: {
    name: 'AppFile',
    title: (fileIdentification: BeneficiaryNames[] | null) =>
      fileIdentification ? formatFamilyFileName(fileIdentification) : '',
    path: getFamilyFileRoute
  },
  AppFileAdd: {
    name: 'AppFileAdd',
    title: 'Créer un dossier',
    path: () => `${FilesRoute}/add`
  },
  AppHistory: {
    name: 'AppHistory',
    title: 'Accompagnements',
    path: (
      options?:
        | {
            notFilled?: FilterFileInstructionNotFilled
            tab?: HistoryTab.FileInstructions
          }
        | { notFilled?: FilterFollowupNotFilled; tab?: HistoryTab.Followups }
    ) => {
      const notFilled = options?.notFilled
        ? `?not-filled=${options.notFilled}`
        : ''
      const tabHash = options?.tab ? `#${options.tab}` : ''

      return `${AppRoute}/history${notFilled}${tabHash}`
    }
  },
  AppHistoryPrint: {
    name: 'AppHistoryPrint',
    title: 'Imprimer la fiche',
    path: (historyId: string, type?: TypeSuivi) => {
      let routeUrl = `${AppRoute}/historyprint/${historyId}/print`
      if (type) {
        routeUrl = routeUrl + `?type=${type}`
      }
      return routeUrl
    }
  }
} as const
