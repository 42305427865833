import { default as emailsuWhjjs3GiZMeta } from "/build/00b2f0db-833c-48c4-8d2a-c5a6657bd606/pages/admin/emails.vue?macro=true";
import { default as integrations6R4pzlergqMeta } from "/build/00b2f0db-833c-48c4-8d2a-c5a6657bd606/pages/admin/integrations.vue?macro=true";
import { default as overviewiY1JBdcyRIMeta } from "/build/00b2f0db-833c-48c4-8d2a-c5a6657bd606/pages/admin/overview.vue?macro=true";
import { default as indexNunHH6ChGYMeta } from "/build/00b2f0db-833c-48c4-8d2a-c5a6657bd606/pages/admin/structures/[id]/index.vue?macro=true";
import { default as subjectsqnzn7G3Yz0Meta } from "/build/00b2f0db-833c-48c4-8d2a-c5a6657bd606/pages/admin/structures/[id]/subjects.vue?macro=true";
import { default as index2XZ0NnJXNTMeta } from "/build/00b2f0db-833c-48c4-8d2a-c5a6657bd606/pages/admin/structures/index.vue?macro=true";
import { default as index23UuNap8JHMeta } from "/build/00b2f0db-833c-48c4-8d2a-c5a6657bd606/pages/admin/users/[id]/index.vue?macro=true";
import { default as addSclKtkmvPeMeta } from "/build/00b2f0db-833c-48c4-8d2a-c5a6657bd606/pages/admin/users/add.vue?macro=true";
import { default as indexRO7HMMGYUVMeta } from "/build/00b2f0db-833c-48c4-8d2a-c5a6657bd606/pages/admin/users/index.vue?macro=true";
import { default as adminev4iv3gQQoMeta } from "/build/00b2f0db-833c-48c4-8d2a-c5a6657bd606/pages/admin.vue?macro=true";
import { default as accountaSIu8d6P8JMeta } from "/build/00b2f0db-833c-48c4-8d2a-c5a6657bd606/pages/app/account.vue?macro=true";
import { default as entourageZz91P1vim3Meta } from "/build/00b2f0db-833c-48c4-8d2a-c5a6657bd606/pages/app/beneficiaries/[id]/edit/entourage.vue?macro=true";
import { default as external_45organisationsvt3lfDSjmUMeta } from "/build/00b2f0db-833c-48c4-8d2a-c5a6657bd606/pages/app/beneficiaries/[id]/edit/external-organisations.vue?macro=true";
import { default as healthLKzxdEhUqEMeta } from "/build/00b2f0db-833c-48c4-8d2a-c5a6657bd606/pages/app/beneficiaries/[id]/edit/health.vue?macro=true";
import { default as infoMedCNP9RXxMeta } from "/build/00b2f0db-833c-48c4-8d2a-c5a6657bd606/pages/app/beneficiaries/[id]/edit/info.vue?macro=true";
import { default as occupationCerqJoYv7SMeta } from "/build/00b2f0db-833c-48c4-8d2a-c5a6657bd606/pages/app/beneficiaries/[id]/edit/occupation.vue?macro=true";
import { default as tax_45householdDUjheQV9W0Meta } from "/build/00b2f0db-833c-48c4-8d2a-c5a6657bd606/pages/app/beneficiaries/[id]/edit/tax-household.vue?macro=true";
import { default as printZq7TRhbZhNMeta } from "/build/00b2f0db-833c-48c4-8d2a-c5a6657bd606/pages/app/beneficiaries/[id]/print.vue?macro=true";
import { default as indexivrhGX69xaMeta } from "/build/00b2f0db-833c-48c4-8d2a-c5a6657bd606/pages/app/beneficiaries/index.vue?macro=true";
import { default as editsKR5BTKFZwMeta } from "/build/00b2f0db-833c-48c4-8d2a-c5a6657bd606/pages/app/files/[id]/budget/edit.vue?macro=true";
import { default as editvt9fOmlmFCMeta } from "/build/00b2f0db-833c-48c4-8d2a-c5a6657bd606/pages/app/files/[id]/expenses/[eid]/edit.vue?macro=true";
import { default as editRyrq23r5dBMeta } from "/build/00b2f0db-833c-48c4-8d2a-c5a6657bd606/pages/app/files/[id]/followups/[fid]/edit.vue?macro=true";
import { default as addDx6bFN8Ic7Meta } from "/build/00b2f0db-833c-48c4-8d2a-c5a6657bd606/pages/app/files/[id]/followups/add.vue?macro=true";
import { default as editjhvXcJ4S6fMeta } from "/build/00b2f0db-833c-48c4-8d2a-c5a6657bd606/pages/app/files/[id]/help-requests/[subject]/[hrid]/edit.vue?macro=true";
import { default as add6oPIgO9RLDMeta } from "/build/00b2f0db-833c-48c4-8d2a-c5a6657bd606/pages/app/files/[id]/help-requests/[subject]/add.vue?macro=true";
import { default as editCqR5w3PWgTMeta } from "/build/00b2f0db-833c-48c4-8d2a-c5a6657bd606/pages/app/files/[id]/help-requests/housing/[hrid]/edit.vue?macro=true";
import { default as addyDLOp27OT6Meta } from "/build/00b2f0db-833c-48c4-8d2a-c5a6657bd606/pages/app/files/[id]/help-requests/housing/add.vue?macro=true";
import { default as editgPgArJeSSkMeta } from "/build/00b2f0db-833c-48c4-8d2a-c5a6657bd606/pages/app/files/[id]/income/[iid]/edit.vue?macro=true";
import { default as indexJlyF5eEkmTMeta } from "/build/00b2f0db-833c-48c4-8d2a-c5a6657bd606/pages/app/files/[id]/index.vue?macro=true";
import { default as addh8n9TtvaZeMeta } from "/build/00b2f0db-833c-48c4-8d2a-c5a6657bd606/pages/app/files/add.vue?macro=true";
import { default as historyKMya0UhEW0Meta } from "/build/00b2f0db-833c-48c4-8d2a-c5a6657bd606/pages/app/history.vue?macro=true";
import { default as printHD2RsbnuFgMeta } from "/build/00b2f0db-833c-48c4-8d2a-c5a6657bd606/pages/app/historyprint/[id]/print.vue?macro=true";
import { default as overviewJz7pWLshIqMeta } from "/build/00b2f0db-833c-48c4-8d2a-c5a6657bd606/pages/app/overview.vue?macro=true";
import { default as partnershqoWANl5wWMeta } from "/build/00b2f0db-833c-48c4-8d2a-c5a6657bd606/pages/app/partners.vue?macro=true";
import { default as stats5nB2zqvRkaMeta } from "/build/00b2f0db-833c-48c4-8d2a-c5a6657bd606/pages/app/stats.vue?macro=true";
import { default as indexwMvODOyHH1Meta } from "/build/00b2f0db-833c-48c4-8d2a-c5a6657bd606/pages/app/structure/index.vue?macro=true";
import { default as subjectsrxqFYhksq4Meta } from "/build/00b2f0db-833c-48c4-8d2a-c5a6657bd606/pages/app/structure/subjects.vue?macro=true";
import { default as usersHTNAr860pfMeta } from "/build/00b2f0db-833c-48c4-8d2a-c5a6657bd606/pages/app/users.vue?macro=true";
import { default as appnDCWmVOOmvMeta } from "/build/00b2f0db-833c-48c4-8d2a-c5a6657bd606/pages/app.vue?macro=true";
import { default as adminGrfaLjf0V6Meta } from "/build/00b2f0db-833c-48c4-8d2a-c5a6657bd606/pages/auth/admin.vue?macro=true";
import { default as loginCv2MUITuKIMeta } from "/build/00b2f0db-833c-48c4-8d2a-c5a6657bd606/pages/auth/login.vue?macro=true";
import { default as logoutOvsxIhXPZXMeta } from "/build/00b2f0db-833c-48c4-8d2a-c5a6657bd606/pages/auth/logout.vue?macro=true";
import { default as validate_45tokenfY9fOx5iFbMeta } from "/build/00b2f0db-833c-48c4-8d2a-c5a6657bd606/pages/auth/validate-token.vue?macro=true";
import { default as manager30iQe5qEVTMeta } from "/build/00b2f0db-833c-48c4-8d2a-c5a6657bd606/pages/cgu/manager.vue?macro=true";
import { default as userHb1WfVN13eMeta } from "/build/00b2f0db-833c-48c4-8d2a-c5a6657bd606/pages/cgu/user.vue?macro=true";
import { default as cguDIMIxlAYkvMeta } from "/build/00b2f0db-833c-48c4-8d2a-c5a6657bd606/pages/cgu.vue?macro=true";
import { default as declaration_45accessibiliteGQSpZ1dlCRMeta } from "/build/00b2f0db-833c-48c4-8d2a-c5a6657bd606/pages/declaration-accessibilite.vue?macro=true";
import { default as disabled_45account0vz9UUYyHHMeta } from "/build/00b2f0db-833c-48c4-8d2a-c5a6657bd606/pages/error/disabled-account.vue?macro=true";
import { default as invalid_45tokenC3vu3a6mkUMeta } from "/build/00b2f0db-833c-48c4-8d2a-c5a6657bd606/pages/error/invalid-token.vue?macro=true";
import { default as unknown_45user_45iccBy5Y2ACzVMeta } from "/build/00b2f0db-833c-48c4-8d2a-c5a6657bd606/pages/error/unknown-user-ic.vue?macro=true";
import { default as unknown_45userGGeYpFMnFVMeta } from "/build/00b2f0db-833c-48c4-8d2a-c5a6657bd606/pages/error/unknown-user.vue?macro=true";
import { default as indexEHofRZWYj0Meta } from "/build/00b2f0db-833c-48c4-8d2a-c5a6657bd606/pages/index.vue?macro=true";
import { default as mentions_45legalesKTQvpRq6GHMeta } from "/build/00b2f0db-833c-48c4-8d2a-c5a6657bd606/pages/mentions-legales.vue?macro=true";
import { default as politique_45confidentialiteqy6Ck8nJSLMeta } from "/build/00b2f0db-833c-48c4-8d2a-c5a6657bd606/pages/politique-confidentialite.vue?macro=true";
import { default as registerzso8s42Xj1Meta } from "/build/00b2f0db-833c-48c4-8d2a-c5a6657bd606/pages/register.vue?macro=true";
import { default as statistiquesFqbhVgtIMOMeta } from "/build/00b2f0db-833c-48c4-8d2a-c5a6657bd606/pages/statistiques.vue?macro=true";
import { default as storiesrBu6pE41TMMeta } from "/build/00b2f0db-833c-48c4-8d2a-c5a6657bd606/pages/stories.vue?macro=true";
export default [
  {
    name: "admin",
    path: "/admin",
    meta: adminev4iv3gQQoMeta || {},
    component: () => import("/build/00b2f0db-833c-48c4-8d2a-c5a6657bd606/pages/admin.vue"),
    children: [
  {
    name: "admin-emails",
    path: "emails",
    component: () => import("/build/00b2f0db-833c-48c4-8d2a-c5a6657bd606/pages/admin/emails.vue")
  },
  {
    name: "admin-integrations",
    path: "integrations",
    component: () => import("/build/00b2f0db-833c-48c4-8d2a-c5a6657bd606/pages/admin/integrations.vue")
  },
  {
    name: overviewiY1JBdcyRIMeta?.name ?? "admin-overview",
    path: "overview",
    meta: overviewiY1JBdcyRIMeta || {},
    component: () => import("/build/00b2f0db-833c-48c4-8d2a-c5a6657bd606/pages/admin/overview.vue")
  },
  {
    name: "admin-structures-id",
    path: "structures/:id()",
    component: () => import("/build/00b2f0db-833c-48c4-8d2a-c5a6657bd606/pages/admin/structures/[id]/index.vue")
  },
  {
    name: "admin-structures-id-subjects",
    path: "structures/:id()/subjects",
    component: () => import("/build/00b2f0db-833c-48c4-8d2a-c5a6657bd606/pages/admin/structures/[id]/subjects.vue")
  },
  {
    name: "admin-structures",
    path: "structures",
    component: () => import("/build/00b2f0db-833c-48c4-8d2a-c5a6657bd606/pages/admin/structures/index.vue")
  },
  {
    name: "admin-users-id",
    path: "users/:id()",
    component: () => import("/build/00b2f0db-833c-48c4-8d2a-c5a6657bd606/pages/admin/users/[id]/index.vue")
  },
  {
    name: "admin-users-add",
    path: "users/add",
    component: () => import("/build/00b2f0db-833c-48c4-8d2a-c5a6657bd606/pages/admin/users/add.vue")
  },
  {
    name: "admin-users",
    path: "users",
    component: () => import("/build/00b2f0db-833c-48c4-8d2a-c5a6657bd606/pages/admin/users/index.vue")
  }
]
  },
  {
    name: "app",
    path: "/app",
    meta: appnDCWmVOOmvMeta || {},
    component: () => import("/build/00b2f0db-833c-48c4-8d2a-c5a6657bd606/pages/app.vue"),
    children: [
  {
    name: "app-account",
    path: "account",
    component: () => import("/build/00b2f0db-833c-48c4-8d2a-c5a6657bd606/pages/app/account.vue")
  },
  {
    name: "app-beneficiaries-id-edit-entourage",
    path: "beneficiaries/:id()/edit/entourage",
    component: () => import("/build/00b2f0db-833c-48c4-8d2a-c5a6657bd606/pages/app/beneficiaries/[id]/edit/entourage.vue")
  },
  {
    name: "app-beneficiaries-id-edit-external-organisations",
    path: "beneficiaries/:id()/edit/external-organisations",
    component: () => import("/build/00b2f0db-833c-48c4-8d2a-c5a6657bd606/pages/app/beneficiaries/[id]/edit/external-organisations.vue")
  },
  {
    name: "app-beneficiaries-id-edit-health",
    path: "beneficiaries/:id()/edit/health",
    component: () => import("/build/00b2f0db-833c-48c4-8d2a-c5a6657bd606/pages/app/beneficiaries/[id]/edit/health.vue")
  },
  {
    name: "app-beneficiaries-id-edit-info",
    path: "beneficiaries/:id()/edit/info",
    component: () => import("/build/00b2f0db-833c-48c4-8d2a-c5a6657bd606/pages/app/beneficiaries/[id]/edit/info.vue")
  },
  {
    name: occupationCerqJoYv7SMeta?.name ?? "app-beneficiaries-id-edit-occupation",
    path: "beneficiaries/:id()/edit/occupation",
    meta: occupationCerqJoYv7SMeta || {},
    component: () => import("/build/00b2f0db-833c-48c4-8d2a-c5a6657bd606/pages/app/beneficiaries/[id]/edit/occupation.vue")
  },
  {
    name: "app-beneficiaries-id-edit-tax-household",
    path: "beneficiaries/:id()/edit/tax-household",
    component: () => import("/build/00b2f0db-833c-48c4-8d2a-c5a6657bd606/pages/app/beneficiaries/[id]/edit/tax-household.vue")
  },
  {
    name: "app-beneficiaries-id-print",
    path: "beneficiaries/:id()/print",
    meta: printZq7TRhbZhNMeta || {},
    component: () => import("/build/00b2f0db-833c-48c4-8d2a-c5a6657bd606/pages/app/beneficiaries/[id]/print.vue")
  },
  {
    name: indexivrhGX69xaMeta?.name ?? "app-beneficiaries",
    path: "beneficiaries",
    meta: indexivrhGX69xaMeta || {},
    component: () => import("/build/00b2f0db-833c-48c4-8d2a-c5a6657bd606/pages/app/beneficiaries/index.vue")
  },
  {
    name: editsKR5BTKFZwMeta?.name ?? "app-files-id-budget-edit",
    path: "files/:id()/budget/edit",
    meta: editsKR5BTKFZwMeta || {},
    component: () => import("/build/00b2f0db-833c-48c4-8d2a-c5a6657bd606/pages/app/files/[id]/budget/edit.vue")
  },
  {
    name: editvt9fOmlmFCMeta?.name ?? "app-files-id-expenses-eid-edit",
    path: "files/:id()/expenses/:eid()/edit",
    meta: editvt9fOmlmFCMeta || {},
    component: () => import("/build/00b2f0db-833c-48c4-8d2a-c5a6657bd606/pages/app/files/[id]/expenses/[eid]/edit.vue")
  },
  {
    name: "app-files-id-followups-fid-edit",
    path: "files/:id()/followups/:fid()/edit",
    component: () => import("/build/00b2f0db-833c-48c4-8d2a-c5a6657bd606/pages/app/files/[id]/followups/[fid]/edit.vue")
  },
  {
    name: "app-files-id-followups-add",
    path: "files/:id()/followups/add",
    component: () => import("/build/00b2f0db-833c-48c4-8d2a-c5a6657bd606/pages/app/files/[id]/followups/add.vue")
  },
  {
    name: "app-files-id-help-requests-subject-hrid-edit",
    path: "files/:id()/help-requests/:subject()/:hrid()/edit",
    component: () => import("/build/00b2f0db-833c-48c4-8d2a-c5a6657bd606/pages/app/files/[id]/help-requests/[subject]/[hrid]/edit.vue")
  },
  {
    name: "app-files-id-help-requests-subject-add",
    path: "files/:id()/help-requests/:subject()/add",
    component: () => import("/build/00b2f0db-833c-48c4-8d2a-c5a6657bd606/pages/app/files/[id]/help-requests/[subject]/add.vue")
  },
  {
    name: "app-files-id-help-requests-housing-hrid-edit",
    path: "files/:id()/help-requests/housing/:hrid()/edit",
    component: () => import("/build/00b2f0db-833c-48c4-8d2a-c5a6657bd606/pages/app/files/[id]/help-requests/housing/[hrid]/edit.vue")
  },
  {
    name: "app-files-id-help-requests-housing-add",
    path: "files/:id()/help-requests/housing/add",
    component: () => import("/build/00b2f0db-833c-48c4-8d2a-c5a6657bd606/pages/app/files/[id]/help-requests/housing/add.vue")
  },
  {
    name: editgPgArJeSSkMeta?.name ?? "app-files-id-income-iid-edit",
    path: "files/:id()/income/:iid()/edit",
    meta: editgPgArJeSSkMeta || {},
    component: () => import("/build/00b2f0db-833c-48c4-8d2a-c5a6657bd606/pages/app/files/[id]/income/[iid]/edit.vue")
  },
  {
    name: "app-files-id",
    path: "files/:id()",
    component: () => import("/build/00b2f0db-833c-48c4-8d2a-c5a6657bd606/pages/app/files/[id]/index.vue")
  },
  {
    name: "app-files-add",
    path: "files/add",
    component: () => import("/build/00b2f0db-833c-48c4-8d2a-c5a6657bd606/pages/app/files/add.vue")
  },
  {
    name: "app-history",
    path: "history",
    component: () => import("/build/00b2f0db-833c-48c4-8d2a-c5a6657bd606/pages/app/history.vue")
  },
  {
    name: "app-historyprint-id-print",
    path: "historyprint/:id()/print",
    meta: printHD2RsbnuFgMeta || {},
    component: () => import("/build/00b2f0db-833c-48c4-8d2a-c5a6657bd606/pages/app/historyprint/[id]/print.vue")
  },
  {
    name: overviewJz7pWLshIqMeta?.name ?? "app-overview",
    path: "overview",
    meta: overviewJz7pWLshIqMeta || {},
    component: () => import("/build/00b2f0db-833c-48c4-8d2a-c5a6657bd606/pages/app/overview.vue")
  },
  {
    name: "app-partners",
    path: "partners",
    component: () => import("/build/00b2f0db-833c-48c4-8d2a-c5a6657bd606/pages/app/partners.vue")
  },
  {
    name: "app-stats",
    path: "stats",
    component: () => import("/build/00b2f0db-833c-48c4-8d2a-c5a6657bd606/pages/app/stats.vue")
  },
  {
    name: "app-structure",
    path: "structure",
    component: () => import("/build/00b2f0db-833c-48c4-8d2a-c5a6657bd606/pages/app/structure/index.vue")
  },
  {
    name: "app-structure-subjects",
    path: "structure/subjects",
    component: () => import("/build/00b2f0db-833c-48c4-8d2a-c5a6657bd606/pages/app/structure/subjects.vue")
  },
  {
    name: "app-users",
    path: "users",
    component: () => import("/build/00b2f0db-833c-48c4-8d2a-c5a6657bd606/pages/app/users.vue")
  }
]
  },
  {
    name: "auth-admin",
    path: "/auth/admin",
    meta: adminGrfaLjf0V6Meta || {},
    component: () => import("/build/00b2f0db-833c-48c4-8d2a-c5a6657bd606/pages/auth/admin.vue")
  },
  {
    name: "auth-login",
    path: "/auth/login",
    meta: loginCv2MUITuKIMeta || {},
    component: () => import("/build/00b2f0db-833c-48c4-8d2a-c5a6657bd606/pages/auth/login.vue")
  },
  {
    name: "auth-logout",
    path: "/auth/logout",
    meta: logoutOvsxIhXPZXMeta || {},
    component: () => import("/build/00b2f0db-833c-48c4-8d2a-c5a6657bd606/pages/auth/logout.vue")
  },
  {
    name: "auth-validate-token",
    path: "/auth/validate-token",
    meta: validate_45tokenfY9fOx5iFbMeta || {},
    component: () => import("/build/00b2f0db-833c-48c4-8d2a-c5a6657bd606/pages/auth/validate-token.vue")
  },
  {
    name: "cgu",
    path: "/cgu",
    meta: cguDIMIxlAYkvMeta || {},
    component: () => import("/build/00b2f0db-833c-48c4-8d2a-c5a6657bd606/pages/cgu.vue"),
    children: [
  {
    name: "cgu-manager",
    path: "manager",
    component: () => import("/build/00b2f0db-833c-48c4-8d2a-c5a6657bd606/pages/cgu/manager.vue")
  },
  {
    name: "cgu-user",
    path: "user",
    component: () => import("/build/00b2f0db-833c-48c4-8d2a-c5a6657bd606/pages/cgu/user.vue")
  }
]
  },
  {
    name: "declaration-accessibilite",
    path: "/declaration-accessibilite",
    meta: declaration_45accessibiliteGQSpZ1dlCRMeta || {},
    component: () => import("/build/00b2f0db-833c-48c4-8d2a-c5a6657bd606/pages/declaration-accessibilite.vue")
  },
  {
    name: "error-disabled-account",
    path: "/error/disabled-account",
    meta: disabled_45account0vz9UUYyHHMeta || {},
    component: () => import("/build/00b2f0db-833c-48c4-8d2a-c5a6657bd606/pages/error/disabled-account.vue")
  },
  {
    name: "error-invalid-token",
    path: "/error/invalid-token",
    meta: invalid_45tokenC3vu3a6mkUMeta || {},
    component: () => import("/build/00b2f0db-833c-48c4-8d2a-c5a6657bd606/pages/error/invalid-token.vue")
  },
  {
    name: "error-unknown-user-ic",
    path: "/error/unknown-user-ic",
    meta: unknown_45user_45iccBy5Y2ACzVMeta || {},
    component: () => import("/build/00b2f0db-833c-48c4-8d2a-c5a6657bd606/pages/error/unknown-user-ic.vue")
  },
  {
    name: "error-unknown-user",
    path: "/error/unknown-user",
    meta: unknown_45userGGeYpFMnFVMeta || {},
    component: () => import("/build/00b2f0db-833c-48c4-8d2a-c5a6657bd606/pages/error/unknown-user.vue")
  },
  {
    name: "index",
    path: "/",
    meta: indexEHofRZWYj0Meta || {},
    component: () => import("/build/00b2f0db-833c-48c4-8d2a-c5a6657bd606/pages/index.vue")
  },
  {
    name: "mentions-legales",
    path: "/mentions-legales",
    meta: mentions_45legalesKTQvpRq6GHMeta || {},
    component: () => import("/build/00b2f0db-833c-48c4-8d2a-c5a6657bd606/pages/mentions-legales.vue")
  },
  {
    name: "politique-confidentialite",
    path: "/politique-confidentialite",
    meta: politique_45confidentialiteqy6Ck8nJSLMeta || {},
    component: () => import("/build/00b2f0db-833c-48c4-8d2a-c5a6657bd606/pages/politique-confidentialite.vue")
  },
  {
    name: "register",
    path: "/register",
    meta: registerzso8s42Xj1Meta || {},
    component: () => import("/build/00b2f0db-833c-48c4-8d2a-c5a6657bd606/pages/register.vue")
  },
  {
    name: "statistiques",
    path: "/statistiques",
    meta: statistiquesFqbhVgtIMOMeta || {},
    component: () => import("/build/00b2f0db-833c-48c4-8d2a-c5a6657bd606/pages/statistiques.vue")
  },
  {
    name: "stories",
    path: "/stories",
    meta: storiesrBu6pE41TMMeta || {},
    component: () => import("/build/00b2f0db-833c-48c4-8d2a-c5a6657bd606/pages/stories.vue")
  }
]